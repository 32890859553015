import React, { FC, ReactNode } from 'react';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import { css } from '@emotion/css';
import clsx from 'clsx';
import { PageLink } from "components/common/atoms";

export type LinkProps = NextLinkProps & {
  children: ReactNode;
  className?: string;
  rel?: string;
  target?: string;
  onClick?: () => void;
};

const Link: FC<LinkProps> = (props) => {
  const { children, className, href, rel, target, ...rest } = props;

  if (String(href).startsWith('https://') || String(href).startsWith('http://')) {
    return (
      <a
        target={'_blank'}
        rel="noreferrer"
        className={clsx(styles.root, className)}
        href={String(href)}
        {...rest}
      >
        {children}
      </a>
    );
  }
  return (
      <PageLink className={clsx(styles.root, className)} rel={rel} target={target} href={String(href)}>
        {children}
      </PageLink>
  );
};

const styles = {
  root: css`
    cursor: pointer;
    text-decoration: none;
    color: #252c32;
  `,
};

export default Link;
