import { triggerSelectServiceEvent } from 'config/seo/events';
import { EV_CHARGING_URL, LIGHTING_RETROFIT_URL, RETAIL_URL } from 'config/seo/urls';

export type SingleService = { name: string, key: string }

export const services = [
  { id: 1, name: 'LED Services', key: 'led-service' },
  { id: 39, name: 'Solar Services', key: 'solar-services' },
  { id: 36, name: 'Battery Storage', key: 'battery-storage' },
  { id: 37, name: 'HVAC', key: 'hvac' },
  { id: 38, name: 'Thermostat', key: 'thermostat' },
  { id: 44, name: 'Demand Response', key: 'demand-response' },
  { id: 41, name: 'Gas Solutions', key: 'gas-solutions' },
  { id: 43, name: 'Water Solutions', key: 'water-solution' },
  { id: 42, name: 'Windows & Doors', key: 'windows-doors' },
  { id: 40, name: 'Find an Electrician', key: 'find-an-electrician' },
  { id: 46, name: 'Installation Services', key: 'installation-services' },
  { id: 47, name: 'Plumbing Services', key: 'plumbing-services' },
];

export const dropdownOptions = [
  {
    href: RETAIL_URL,
    label: 'services.retail-energy',
    onClick: () => {
      triggerSelectServiceEvent('retail_energy');
    },
  },
  {
    href: EV_CHARGING_URL,
    label: 'services.ev-charging',
    onClick: () => {
      triggerSelectServiceEvent('ev_charging');
    },
  },
  {
    href: LIGHTING_RETROFIT_URL,
    label: 'services.lighting-retrofit',
    onClick: () => {
      triggerSelectServiceEvent('lighting_retrofit');
    },
  },
  ...services.map((el) => ({
    href: `/services/${el.key}`,
    label: el.name,
    onClick: () => triggerSelectServiceEvent(el.key)
  })),
];
