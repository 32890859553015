import React, { CSSProperties, FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import { Theme } from 'evcharging/app/themes';
import useTheme from 'evcharging/hooks/useTheme';
import clsx from 'clsx';

interface SectionProps extends CSSProperties {
  children: ReactNode;
  background?: 'dark' | 'middle' | 'light';
}

const Section: FC<SectionProps> = (props) => {
  const { background, children, ...cssProperties } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { background });

  return <section className={clsx(styles.root, css({ ...cssProperties }))}>{children}</section>;
};

const createStyles = (theme: Theme, { background = 'light' }: Partial<SectionProps>) => ({
  root: css`
    padding: 98px 0;
    background-color: ${theme.colors.background[background]};
    ${theme.breakpoints.mobile.down} {
      padding: 18px 0;
    }
  `,
});

export default Section;
