import React from 'react';
import FooterDesktop from 'evcharging/containers/layout/FooterDesktop';
import Desktop from 'evcharging/components/Desktop';
import Mobile from 'evcharging/components/Mobile';
import FooterMobile from 'evcharging/containers/layout/FooterMobile';

const Footer = () => {
  return (
    <>
      <Desktop>
        <FooterDesktop />
      </Desktop>
      <Mobile>
        <FooterMobile />
      </Mobile>
    </>
  );
};

export default Footer;
