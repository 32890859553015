import React, { FC } from 'react';
import { css } from '@emotion/css';
import { Theme } from 'evcharging/app/themes';
import useTheme from 'evcharging/hooks/useTheme';

interface InlineGapProps {
  desktop: number;
  mobile?: number;
}

const InlineGap: FC<InlineGapProps> = (props) => {
  const theme = useTheme();
  const styles = createStyles(theme, props);
  return <span className={styles.root} />;
};

const createStyles = (theme: Theme, props: InlineGapProps) => ({
  root: css`
    display: inline-block;
    margin-left: ${props.desktop}px;
    ${theme.breakpoints.mobile.down} {
      margin-left: ${props.mobile ?? props.desktop}px;
    }
  `,
});

export default InlineGap;
