import React, { FC, ReactNode } from 'react';
import lightTheme from 'evcharging/app/themes/lightTheme';
import BaselineCss from 'evcharging/components/BaselineCss';
import { ThemeProvider } from '@emotion/react';

interface EvcAdapterProps {
  children: ReactNode;
}

const EvcAdapter: FC<EvcAdapterProps> = ({ children }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <BaselineCss />
      {children}
    </ThemeProvider>
  );
};

export default EvcAdapter;
