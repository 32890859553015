import React, { FC } from 'react';
import { css } from '@emotion/css';
import Link from 'evcharging/components/Link';
import logo from 'evcharging/assets/layout/logo.png';
import Image from 'next/image';

interface FooterLogoLinkProps {
  href: string;
}

const FooterLogoLink: FC<FooterLogoLinkProps> = (props) => {
  const { href } = props;
  return (
    <Link href={href} className={styles.root}>
      <Image src={logo} width={131} height={38} alt={'logo'} />
    </Link>
  );
};

const styles = {
  root: css``,
};

export default FooterLogoLink;
