import React, { useState } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import Link from 'next/link';
import ClickAwayListener from 'react-click-away-listener';
import { Theme } from 'evcharging/app/themes';
import InlineGap from 'evcharging/components/InlineGap';
import LangArrowIcon from 'evcharging/components/icons/LangArrowIcon';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import useI18n from "evcharging/hooks/useI18n";

const list = [
  { locale: 'en', label: 'English' },
  { locale: 'es', label: 'Spanish' },
];

const NavLangs = () => {
  const theme = useTheme();
  const { links } = useI18n();
  const styles = createStyles(theme);
  const [open, setOpen] = useState<boolean>(false);
  const onToggle = () => setOpen(!open);
  const { locale, asPath } = useRouter();

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={clsx(styles.root, open && styles.open)}>
        <button className={styles.button} onClick={onToggle}>
          {locale}
          <InlineGap desktop={3} />
          <LangArrowIcon />
        </button>
        {open && (
          <div className={styles.nested}>
            {list.map((item) => (
              <Link key={item.locale} href={links[item.locale] || asPath} locale={item.locale}>
                <a onClick={onToggle}>
                  {item.label}
                </a>
              </Link>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

const createStyles = (theme: Theme) => ({
  root: css`
    position: relative;
    white-space: nowrap;
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-left: 32px;

    ${theme.breakpoints.mobile.down} {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      svg {
        path {
          fill: white;
        }
      }
    }

    :hover {
      color: ${theme.colors.accent};
      svg {
        path {
          transition: all 0.2s ease-in-out;
          fill: ${theme.colors.accent};
        }
      }
    }
  `,
  open: css`
    svg {
      transform: rotate(180deg);
    }
  `,
  button: css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    outline: none;
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #dde2e4;
    border-radius: 6px;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    cursor: pointer;
    text-transform: capitalize;
    ${theme.breakpoints.mobile.down} {
      width: 42px;
      height: 42px;
    }
  `,
  nested: css`
    position: absolute;
    top: 65px;
    right: 0;
    background: #ffffff;
    box-shadow: 0 11px 23px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 8px 0;
    min-width: 140px;
    z-index: 10000;

    ${theme.breakpoints.mobile.down} {
      left: auto;
      min-width: 100%;
    }

    a {
      display: block;
      padding: 8px 16px;
      text-align: left;
      color: #333;
      font-size: 16px;
      font-weight: 600;

      ${theme.breakpoints.mobile.down} {
        text-align: center;
        padding: 12px 16px;
      }

      :hover {
        background-color: #f6f8f9;
      }
    }
  `,
});

export default NavLangs;
