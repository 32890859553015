import React, { FC } from 'react';
import { css } from '@emotion/css';
import { Theme } from 'evcharging/app/themes';
import useTheme from 'evcharging/hooks/useTheme';

interface SpacerProps {
  value: number;
  mobile?: number;
}

const Spacer: FC<SpacerProps> = (props) => {
  const theme = useTheme();
  const styles = createStyles(theme, props);

  return <div className={styles.root} />;
};

const createStyles = (theme: Theme, props: SpacerProps) => ({
  root: css`
    margin-bottom: ${props.value}px;
    ${theme.breakpoints.mobile.down} {
      margin-bottom: ${props.mobile ?? props.value}px;
    }
  `,
});

export default Spacer;
