import React, { FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import { CSSProperties } from '@emotion/serialize';
import clsx from 'clsx';

interface BoxProps extends CSSProperties {
  children?: ReactNode;
  className?: string;
}

const Box: FC<BoxProps> = (props) => {
  const { children, className, ...cssProperties } = props;
  return <div className={clsx(css({ ...cssProperties }), className)}>{children}</div>;
};

export default Box;
