import React, { CSSProperties, FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import { Theme } from 'evcharging/app/themes';
import useTheme from 'evcharging/hooks/useTheme';
import clsx from 'clsx';

interface ContainerProps extends CSSProperties {
  children: ReactNode;
  mobileFullWidth?: boolean;
}

const Container: FC<ContainerProps> = (props) => {
  const { children, mobileFullWidth, overflow = 'hidden', ...cssProperties } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { mobileFullWidth });

  return <div className={clsx(styles.root, css({ ...cssProperties }))}>{children}</div>;
};

const createStyles = (theme: Theme, { mobileFullWidth, overflow }: Partial<ContainerProps>) => ({
  root: css`
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 12px;
    ${theme.breakpoints.mobile.down} {
      padding: 0 ${mobileFullWidth ? '0' : '15px'};
      overflow: ${overflow};
    }
  `,
});

export default Container;
