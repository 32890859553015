import React, { FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';

interface MobileProps {
  children: ReactNode;
}

const Mobile: FC<MobileProps> = (props) => {
  const { children } = props;
  const theme = useTheme();

  return (
    <div
      className={css`
        display: block;
        visibility: visible;
        ${theme.breakpoints.mobile.up} {
          display: none;
          visibility: hidden;
        } ;
      `}
    >
      {children}
    </div>
  );
};

export default Mobile;
