import React, { FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';

interface DesktopProps {
  children: ReactNode;
  height?: string;
}

const Desktop: FC<DesktopProps> = (props) => {
  const { children, height } = props;
  const theme = useTheme();

  return (
    <div
      className={css`
        display: block;
        visibility: visible;
        ${height ? `height: ${height};` : ''}
        ${theme.breakpoints.mobile.down} {
          display: none;
          visibility: hidden;
        } ;
      `}
    >
      {children}
    </div>
  );
};

export default Desktop;
